import styled from 'styled-components'

export const AcademiaWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 13rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 520px;
  padding: 0 3rem 3rem 3rem;

  h1 {
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.fontSize['5xl']};
    font-weight: ${({ theme }) => theme.fontWeight['bold']};
    line-height: ${({ theme }) => theme.lineHeight['none']};
    margin-bottom: 1.25rem;
  }

  p {
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: 0.4rem 0;
  }

  a {
    color: ${({ theme }) => theme.color.vividBlue};
  }
`

export const ImageContainer = styled.div`
  width: 65%;
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  opacity: 0;
  animation: fadeInLow 0.6s 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;

  @keyframes fadeInLow {
    100% {
      opacity: ${({ opacity }) => (opacity ? opacity : 1)};
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 300px;
  }
`

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.remToPx(35)};

  h2 {
    color: ${({ theme }) => theme.color.grey};
    font-size: ${({ theme }) => theme.fontSize['3xl']};
    font-weight: bold;
    margin: ${({ theme }) => theme.remToPx(10)} 0;
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSize['base']};
    font-weight: bold;
    margin: ${({ theme }) => theme.remToPx(10)} 0;
  }
  h4 {
    font-size: ${({ theme }) => theme.fontSize['sm']};
    font-weight: bold;
  }
`

export const Category = styled.div`
  margin-bottom: ${({ theme }) => theme.remToPx(15)};
`

export const LinkItem = styled.div`
  display: block;
  margin: ${({ theme }) => theme.remToPx(10)} 0;
  font-size: ${({ theme }) => theme.fontSize['sm']};
  line-height: 1rem;

  strong {
    font-weight: bold;
  }

  a {
    position: relative;
    display: block;
    color: black;

    &::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: -10px;
      width: 5px;
      height: 100%;
      background-color: ${({ theme }) => theme.color.vividBlue};
      transition: opacity 0.2s ease-in-out;
    }
  }

  :hover {
    a::after {
      opacity: 1;
    }
  }
`
